import React, { Component } from 'react';
import deep17 from "./images/deep17.jpg"
import axios from "axios"
import logo from "./images/ligi.jpg"
import "../main.css"  
import {Link} from "react-router-dom"
import jsPDF from "jspdf"
// import html2canvas from "html2canvas"
import Cookie from "js-cookie"

class Elementalcost extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            elemcost:[],
            category:"",
            subcategory:"",
            subcat:"",
            location:"",
            description:"",
            filteredelemcost:[],
            downloaddisplay:"none",
            locations:[]
         }
    }
    componentDidMount =()=>{
        axios.get(`https://api.niqsdatabank.com/api/v1/elementalcost`)
        .then(res => {
            let realdata = res.data.data
            let locations = []
            realdata.map(d=>{
                if(!locations.includes(d.location)){
                    locations.push(d.location)
                              }

            })
            this.setState({locations})
            this.setState({elemcost:realdata})
    console.log("res.data",res.data)})
        .catch(err=> console.log(err))
    }
    change =(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    /**
     *  downloadtable=()=>{
        this.setState({downloaddisplay:"block"})
        /*
        const doc = new jsPDF()
        doc.text("Elemental Cost Analysis-C",14,30)
doc.autoTable({startY:35, html: '#table',margin: { top: 10 }, })
doc.save("elementalcost_c.pdf")

window.location.href=`https://a.niqsdatabank.org/${this.state.allelemcost[0].file}`
this.setState({downloaddisplay:"none"})
      }  
     */
    /*
    downloadtable=()=>{
        this.setState({downloaddisplay:"block"})
        const input = document.getElementById('table');
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', 0, 0);
            // pdf.output('dataurlnewwindow');
            pdf.save("elemcost.pdf");
          })
        ;
this.setState({downloaddisplay:"none"})
      }  
      */
    showdata =()=>{
        const filteredelemcost =[]
        this.state.elemcost.map( cost =>{
if(this.state.location.toLowerCase() === cost.location.toLowerCase() && this.state.category.toLowerCase() === cost.project_category.toLowerCase() && this.state.subcat.toLowerCase() === cost.project_type.toLowerCase()){
                filteredelemcost.push(cost)
            }
        })
        this.setState({filteredelemcost})
    }
    navigate=(id)=>{
       
        const found = this.state.elemcost.find(element => element.id == id);
        window.location.href=`https://a.niqsdatabank.org/${found.file}`
    }
    render() { 
        const location =[]
        const category =[]
        const subcategory =[]
        const description= []
        
        //add rendering of description to show in table
        this.state.elemcost.map(cat =>{
            if(!location.includes(cat.location)){
                location.push(cat.location)
            }
            if(!category.includes(cat.project_category) && cat.location === this.state.location){
                category.push(cat.project_category)
            }
            if(!subcategory.includes(cat.project_type) && cat.location === this.state.location && cat.project_category === this.state.category){
                subcategory.push(cat.project_type)
            }
            // added description here
             if(!description.includes(cat.project_description) && cat.location === this.state.location && cat.project_category === this.state.category && cat.project_type === this.state.subcategory){
               description.push(cat.project_description)
            }
            
        })
       
        /**
         *    <div className="row mt-3" style={{backgroundColor:"lightblue",color:"black"}}>
                     <div className="col-12 text-primary" style={{padding:"15px"}}>
                         <center>
                             <h2>ELEMENTAL COST ANALYSIS</h2>
                         </center>
                     </div>
                 </div>
         */
        return (
            <div>
            <div className="container" style={{width:"100%",height:"100%",overflow:"hidden"}}>
             <div className="row" style={{zIndex:"2",backgroundColor:"lightblue",padding:"0px"}}>
                    <div className="col-3 col-md-2" style={{padding:"0px",margin:"0px"}}>
                    <img style={{width:"100%",padding:"0px", margin:"0px",height:"100%"}} className="img-responsive" src={`${deep17}`} alt=""/>
                    </div>
                    <div className="col-9 col-md-10 text-primary">
                    <center>
                    <small style={{fontSize:"20px",fontWeight:"bold"}}>ELEMENTAL COST ANALYSIS</small>
                    <div className="mb-1" style={{border:"1px solid white",borderRadius:"5px",fontSize:"18px", color:"black", padding:"10px 0px"}}><small style={{padding:'10px'}}>This is used for budgeting, cost planning and cost control of projects from inception to completion. It can also be used as a basis for advising Clients on future projects. This section covers different types of projects: Residential, Commercial, Educational, Religious, etc. Each Analysis page comprises a brief description of the project, its location, year of execution, gross floor area and elemental costs.</small> </div>
                    </center>
                    </div>                  
                 </div>
                 <br/>
                 <div  style={{padding:"0px 20px"}}>
                 <div className="row mt-3" style={{borderBottom:"2px solid lightgrey"}}>
                <div className="col-12 col-md-3"> 
                     <select name="location" className="form-control" id="location" onChange={this.change} value={this.state.location}>
               <option value="">Select Location</option>
              {this.state.locations.map(state =>                 
               <option value={`${state}`}>{state}</option>
               )}
             </select><br/>
                     </div>    
                     <br/>
                     
                     <div className="col-12 col-md-3"> 
                     <select name="category" className="form-control" id="category" onChange={this.change} value={this.state.category}>
               <option value="">Select Project_Category</option>
              {category.map(cat =>                 
               <option value={`${cat}`}>{cat}</option>
               )}
             </select><br/>
                     </div>    
                     <br/>
                     <div className="col-12 col-md-3"> 
                     <select name="subcat" className="form-control" id="subcat" onChange={this.change} value={this.state.subcat}>
               <option value="">Select Project_Type</option>
              {subcategory.map(subcat =>                 
               <option value={`${subcat}`}>{subcat}</option>
               )}
             </select><br/>
                     </div>    
                     <br/>
                    
                     <div className="col-12 col-md-3 mb-5">
                         <button onClick={this.showdata} className="btn btn-primary " style={{float:"right"}}>
                             <small>
                                 Show Result
                             </small>
                         </button>
                     </div>
                 </div>
                 </div>
    <div id="table" style={{overflow:"auto"}}>
                 {this.state.filteredelemcost.length > 0 ? 
                 <div className="mb-2 mt-1 elemcostdiv" style={{display:"flex",flexWrap:"nowrap",borderBottom:"2px solid blue"}}>
                     <div style={{textTransform:"uppercase",fontWeight:"bold",width:"10%"}}>
                         
                     </div>
                     <div style={{textTransform:"uppercase",fontWeight:"bold",width:"30%", margin:"13px 0px 0px 0px"}}>
                       <center>
                       <p>Location</p>
                       </center>
                     </div>
    {/* //added description to table header */}
                     <div style={{textTransform:"uppercase",fontWeight:"bold",width:"50%",  margin:"13px 0px 0px 0px"}}>
                       <center>
                       <p>Description</p>
                       </center>
                     </div>
                   
                 </div>
    : null}
                 {this.state.filteredelemcost.map(cat => 
                 <div onClick={()=>this.navigate(cat.id)} style={{color:"black",cursor:"pointer",textDecoration:"none"}}>
                    <div className="mb-3 elemcostdiv" style={{display:"flex",flexWrap:"nowrap",padding:"10px 10px",borderBottom:"1px solid grey"}}>
                    <div style={{width:"10%"}}>
                       <center>
                       <img src={logo} alt="" style={{width:"80%",height:"90px"}}/>
                       </center>
                    </div>
                    <div style={{width:"30%", padding:"30px 0px 0px 0px "}}>
                        <center>
                        <p>{cat.location}</p>
                        </center>
                    </div>
                    {/* //added column to show the description */}
                    <div style={{width:"50%" , verticalAlign:"center", padding:"10px 0px 0px 0px "}}>
                        <center>
                        <p>{cat.project_description}</p>
                        </center>
                    </div>
                   
                </div>
                </div>
                    )}
                  
                  </div>
                 <br/><br/><br/><br/>
                 <div className="row">
                    <div className="col-12">                    
                  <br/><br/>
                    <span style={{display:`${this.state.downloaddisplay}`,float:"right"}}><small>downloading pdf...</small></span>
                </div>
                </div>
                <br/><br/>
                <div>
                {Cookie.get("cyxpzz") ?
                    <a href="/forms/elementalcost">
                    <button className="btn btn-primary" style={{float:"right"}}>
                       + Add Item
                    </button>
                    </a>
                    : null}
                </div>
             </div>
             </div>
         ); 
    }
}
 
export default Elementalcost;