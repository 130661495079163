import React, { Component } from 'react';
import {states} from "../state"
import axios from "axios"
import Cookie from "js-cookie"
import {Redirect} from "react-router-dom"

class ElementalCost extends Component {
    constructor(props) {
        super(props);
        this.state = { 
         categories:[],
         subcategories:[],
         category:"",
         buildingType:"",
         grossArea:"",
         description:"",
         clientType:"",
         location:"",
         duration:null,
         year:null,
         exchangeRate:"",
         othercategoriesdisplay:"none",
         othercategoriesIcon:"fa fa-chevron-right",
         facilitatingWorksheight:"0px",
         facilitatingWorksIcon:"fa fa-chevron-right",
         substructureheight:"0px",
         substructureIcon:"fa fa-chevron-right",
         superstructureheight:"0px",
         superstructureIcon:"fa fa-chevron-right",
         finishesheight:"0px",
         finishesIcon:"fa fa-chevron-right",
         fittingsheight:"0px",
         fittingsIcon:"fa fa-chevron-right",
         installationheight:"0px",
         installationIcon:"fa fa-chevron-right",
         existingUnitsheight:"0px",
         existingUnitsIcon:"fa fa-chevron-right",
         externalWorksheight:"0px",
         externalWorksIcon:"fa fa-chevron-right",

         demoWorkselemcost:"",
         demoworkcost:"",
         specgroundelemcost:"",
         specgroundcost:null,
         siteinvestelemcost:"",
         siteinvestcost:"",

         substructureelemcost:"",
         substructurecost:"",

         frameelemcost:"",
         framecost:"",
         upfloorelemcost:"",
         upfloorcost:"",
         roofelemcost:"",
         roofcost:"",
         stairelemcost:"",
         staircost:"",
         exwallelemcost:"",
         exwallcost:"",
         winexdoorelemcost:"",
         winexdoorcost:"",
         intwallselemcost:"",
         intwallscost:"",
         intdoorselemcost:"",
         intdoorscost:"",

         wallfinelemcost:"",
         wallfincost:"",
         floorfinelemcost:"",
         floorfincost:"",
         ceilingfinelemcost:"",
         ceilingfincost:"",

         fitfurequipelemcost:"",
         fitfurequipcost:"",

        sanappinstallelemcost:"",
        sanappinstallcost:"",
        hotcoldwaterelemcost:"",
        hotcoldwatercost:"",
        disposalinstallelemcost:"",
        disposalinstallcost:"",
        hvacinstallelemcost:"",
        hvacinstallcost:"",
        electinstallelemcost:"",
        electinstallcost:"",
        geninstallelemcost:"",
        geninstallcost:"",
        liftconveyorelemcost:"",
        liftconveyorcost:"",
        fireprotectelemcost:"",
        fireprotectcost:"",
        commcontrolelemcost:"",
        commcontrolcost:"",
        specinstallelemcost:"",
        specinstallcost:"",
        acinstallelemcost:"",
        acinstallcost:"",
        buildworkelemcost:"",
        buildworkcost:"",

         renworkselemcost:"",
         renworkscost:"",

         siteprepelemcost:"",
          siteprepcost:"",
          roadspathpavelemcost:"",
          roadspathpavcost:"",
          landscaplantingelemcost:"",
          landscaplantingcost:"",
          fencingelemcost:"",
          fencingcost:"",
          explumbingelemcost:"",
          explumbingcost:"",
          exelectelemcost:"",
          exelectcost:"",
          exdrainageelemcost:"",
          exdrainagecost:"",
          minorbuildingelemcost:"",
          minorbuildingcost:"",

          subtotal_fworks:"",
          main_cp:"",
          subtotal_fworks_cp:"",
          proteam_fees:"",
          base_ce:"",
          cont_ra:"",
          total_ce_vat:"",
          vat:"",
          total_ce_vat:"",
          messagebox:"",
          uploadcsv:false,
          csv:null,
          messageboxclass:"d-none alert-primary",
          redirect:false,
          projectdescription:"",
          projectcategory:"",
          projecttype:"",
          location:""

         }
    }
    componentDidMount=()=>{
        const categories =[{"name":"Hotel Development","subcat":["5-Star Hotel","4-Star Hotel","3-Star Hotel","2-Star Hotel","Others"]},
        {"name":"Office Development","subcat":["Low-Rise Office Buildings (Below 4 Storey)","Medium-Rise Office Buildings (5-14 Storey)",
        "High-Rise Office Buildings (15-25 Storey)","Extra High-Rise Office Buildings (Over 25 Storey)"]},
      {"name":"Educational Buildings","subcat":["Below 2000 Capacity Theatre","2001 up to 5000 Capacity Theatre","below 2000 capacity cafeteria/ dinning hall",
    "classroom block (state no of classrooms)","student hostel","senate building","administration block","laboratory building",
"workshop","library"]},
{"name":"residential development","subcat": ["bungalows","duplexes","terrace houses","up to 4-storey block of flats", "high-rise luxury apartments (indicate no of storeys)"]},
{"name":"religious buildings","subcat":["church auditorium (indicate capacity)","mosque building (indicate capacity) "]},
{"name":"industrial/ commercial development","subcat" : ["factory building", "stores/ warehouses", "multi- storesy car park",
"petrol stations", "shopping centers", "corner shops", "bank buildings", "markets"]},
{"name":"health - related development","subcat" : ["health centre", "general hospital", "teaching hospital"]},
{"name":"sports/ recreational facilities (indicate capacity)","subcat" : ["sports stadium","indoor sports hall","lawn tennis court", "hand ball court", "basket ball court",
"golf course", "table tennis court"]},
{"name":"others" ,"subcat":["community halls", "skill acquisition centres"]}]
this.setState({categories})
axios.get("https://a.niqsdatabank.org/file/elemental/ECATemplate.xls")
.then(res => console.log("res", res.data))
.catch(err => console.log(err))
    }
    change=(e)=>{
        this.setState({[e.target.name]:e.target.value},()=>{

            let subcategories;
            this.state.categories.map(category=>{
                if(category.name === this.state.category){
                subcategories = category.subcat
                }
            })
            this.setState({subcategories:subcategories},()=>{
                
            })
        })
    }
    toggleOthercategories=()=>{
        if(this.state.othercategoriesdisplay === "none"){
            this.setState({othercategoriesdisplay:"",othercategoriesIcon:"fa fa-chevron-up"})
        }else{
            this.setState({othercategoriesdisplay:"none",othercategoriesIcon:"fa fa-chevron-right"})
        }
    }
    togglefacilitatingworks=()=>{
        if(this.state.facilitatingWorksheight === "0px"){
            this.setState({facilitatingWorksheight:"100%"})
        }else{
            this.setState({facilitatingWorksheight:"0px"})
        }
    }
    togglesubstructure=()=>{
        if(this.state.substructureheight === "0px"){
            this.setState({substructureheight:"100%"})
        }else{
            this.setState({substructureheight:"0px"})
        }
    }
    togglesuperstructure=()=>{
        if(this.state.superstructureheight === "0px"){
            this.setState({superstructureheight:"100%"})
        }else{
            this.setState({superstructureheight:"0px"})
        }
    }
    togglefinishes=()=>{
        if(this.state.finishesheight === "0px"){
            this.setState({finishesheight:"100%"})
        }else{
            this.setState({finishesheight:"0px"})
        }
    }
    togglefittings=()=>{
        if(this.state.fittingsheight === "0px"){
            this.setState({fittingsheight:"100%"})
        }else{
            this.setState({fittingsheight:"0px"})
        }
    }
    toggleinstallation=()=>{
        if(this.state.installationheight === "0px"){
            this.setState({installationheight:"100%"})
        }else{
            this.setState({installationheight:"0px"})
        }
    }
    toggleexistingunits=()=>{
        if(this.state.existingUnitsheight === "0px"){
            this.setState({existingUnitsheight:"100%"})
        }else{
            this.setState({existingUnitsheight:"0px"})
        }
    }
    toggleexternalworks=()=>{
        if(this.state.externalWorksheight === "0px"){
            this.setState({externalWorksheight:"100%"})
        }else{
            this.setState({externalWorksheight:"0px"})
        }
    }
    filechange=(e)=>{
        this.setState({csv:e.target.files[0]})
       }
      
       submitcsv=()=>{
           console.log("sending")
           const token = Cookie.get("cyxpzz")
           if(!token){
               this.setState({redirect:true})
           }
           else if(this.state.csv === null){
               alert("Warning!!!! Please Select a file")
           }else{
           const config = {
               headers: { Authorization: `Bearer ${token}` }
           };
           var formData = new FormData();
   formData.append("file",this.state.csv);
   formData.append("location",this.state.location);
   formData.append("project_type",this.state.projecttype);
   formData.append("project_category",this.state.projectcategory);
   formData.append("project_description", this.state.projectdescription)
   console.log("formdata", formData,this.state.csv)
  axios.post(`https://api.niqsdatabank.com/api/v1/elementalcost`,formData,config)
           .then(res => {
             
               if(res.data.message && res.data.data){
                   this.setState({messagebox:res.data.message,messageboxclass:"alert-success"},()=>{
                       window.scrollTo(0,  0)
               this.setState({csv:null})
          setTimeout(()=>this.setState({messagebox:"",messageboxclass:"d-none"}),5000)               
   })
               }else{
                this.setState({redirect:true})
               }
           })
           .catch(err=> {
               console.log(err.response, err.message)
               this.setState({messagebox:"Upload Failed",messageboxclass:"alert-danger"},()=>{
                    window.scrollTo(0,  0)
                   setTimeout(()=>this.setState({messagebox:"",messageboxclass:"d-none"}),5000)
               })
           })
           console.log("sent")
       }
    }
   
    render() { 
        if(this.state.redirect){
            return <Redirect to={{ pathname: '/login',state: { from: this.props.location }}} />
        }
        console.log(this.state)
        let year = 1970
         let years = []
         let dummyArray = new Array(52).fill(undefined)
         dummyArray.map((array,i)=>{
                 years.push(year+i)
         })
       const  clientType=["Public", "Private","High End"]
       const categories =[{"name":"Hotel Development","subcat":["5-Star Hotel","4-Star Hotel","3-Star Hotel","2-Star Hotel","Others"]},
        {"name":"Office Development","subcat":["Low-Rise Office Buildings (Below 4 Storey)","Medium-Rise Office Buildings (5-14 Storey)",
        "High-Rise Office Buildings (15-25 Storey)","Extra High-Rise Office Buildings (Over 25 Storey)"]},
      {"name":"Educational Buildings","subcat":["Below 2000 Capacity Theatre","2001 up to 5000 Capacity Theatre","below 2000 capacity cafeteria/ dinning hall",
    "classroom block (state no of classrooms)","student hostel","senate building","administration block","laboratory building",
"workshop","library"]},
{"name":"residential development","subcat": ["bungalows","duplexes","terrace houses","up to 4-storey block of flats", "high-rise luxury apartments (indicate no of storeys)"]},
{"name":"religious buildings","subcat":["church auditorium (indicate capacity)","mosque building (indicate capacity) "]},
{"name":"industrial/ commercial development","subcat" : ["factory building", "stores/ warehouses", "multi- storesy car park",
"petrol stations", "shopping centers", "corner shops", "bank buildings", "markets"]},
{"name":"health - related development","subcat" : ["health centre", "general hospital", "teaching hospital"]},
{"name":"sports/ recreational facilities (indicate capacity)","subcat" : ["sports stadium","indoor sports hall","lawn tennis court", "hand ball court", "basket ball court",
"golf course", "table tennis court"]},
{"name":"others" ,"subcat":["community halls", "skill acquisition centres"]}]
        return ( 
            <div className="container">
                    <div className="row" >     
                    <div className={`col-12 alert ${this.state.messageboxclass}`}>
                   <center>
                       <small style={{fontWeight:"bolder"}}>{this.state.messagebox}</small>
                   </center>
               </div>     
               <div className="col-12 col-md-4 col-lg-4">
                   <br/>
               <select name="location" className="form-control" id="location" onChange={this.change} value={this.state.location}>
               <option value="">Select Location</option>
              {states.map(state =>                 
               <option value={`${state.state.name}`}>{state.state.name}</option>
               )}
             </select>
               </div>
               <div className="col-12 col-md-4 col-lg-4">
                   <br/>
                   <input type="text" className="form-control"  onChange={this.change} placeholder="Project Category" name="projectcategory" value={this.state.projectcategory}/>
               </div>
               <div className="col-12 col-md-4 col-lg-3">
                   <br/>
                   <input type="text" className="form-control"  onChange={this.change} placeholder="Project Type" name="projecttype" value={this.state.projecttype}/>
               </div>
               {/* //added description */}
               <div className="col-12 mt-3">
                   
                        <input type="text"  onChange={this.change} placeholder="Description" name="projectdescription" value={this.state.projectdescription} className="form-control"/>
                    </div>
                    {/* //stopped adding description */}
              <div className="col-12 mt-3">
                  <input type="file" name="csv" onChange={this.filechange} className="form-control"/><br/><br/>
              </div>
              <div className="col-sm-12 col-md-6 mb-3">
                  <button onClick={this.submitcsv} style={{float:"right"}}  className="btn btn-primary">
                      Upload CSV
                  </button>
              </div>
          </div>
               
            </div>
         );
    }
}
 
export default ElementalCost;